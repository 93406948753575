<template>
  <form @submit.prevent="forgotPassword" class="register-form">
    <h3 class="is-size-5 has-text-weight-bold has-text-centered mb-2">{{ translatedText('forgot_password') }}</h3>

    <b-field :label="translatedText('email')" :message="emailError">
      <b-input v-model="email" type="email" :placeholder="translatedText('email')"></b-input>
    </b-field>

    <b-button @click="forgotPassword" type="is-info" expanded>{{ translatedText('recover_button') }}</b-button>
  </form>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      email: '',
      emailError: null,
    };
  },
  methods: {
    ...mapActions('auth', ['recoveryUser']),
    async forgotPassword() {
      if (!this.email) {
        this.emailError = this.translatedText('please_enter_email');
        return;
      }

      await this.recoveryUser({
        email: this.email,
      });
    },
  },
};
</script>
