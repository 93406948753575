<template>
  <div v-if="loyaltyContent" class="loyalty-page">
    <div class="loyalty-header">
      <div class="container">
        <h2 class="title">{{ translatedText('loyalty_page_title') }}</h2>
        <div class="columns">
          <div class="column is-4">
            <figure class="image header-image">
              <img :src="loyaltyContent.imagen" alt="Placeholder image" />
            </figure>
          </div>
          <div class="column is-8">
            <div class="description" v-html="loyaltyContent.contenido"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="participating-theaters">
      <div class="container">
        <h2 class="title">{{ translatedText('loyalty_participating_theaters_title') }}</h2>
        <div class="theaters-cards">
          <div class="columns is-multiline">
            <div v-for="foro in loyaltyContent.foros" :key="foro.id" class="column is-3">
              <TheaterCard :theater="foro" :show-details="true" />
            </div>
          </div>
        </div>
        <p class="subtitle">
          {{ translatedText('loyalty_participating_theaters_subtitle') }}
        </p>
        <div class="show-all">
          <b-button
            class="is-medium vat-btn"
            outlined
            tag="router-link"
            :to="{ name: 'cartelera', query: { filter: 'espectadorfrecuente' } }"
            type="is-link"
          >
            <img src="@/assets/images/star.png" alt="Warranty Logo" />
            {{ translatedText('loyalty_show_all_button') }}
          </b-button>
        </div>
      </div>
    </div>
    <div class="obtain-it">
      <div class="container">
        <h2 class="title">{{ translatedText('loyalty_how_to_obtain_title') }}</h2>
        <div class="obtain-it-cards">
          <div class="columns is-multiline is-mobile">
            <div v-for="paso in loyaltyContent.pasos" :key="paso.id" class="column is-one-fifth-desktop is-half-mobile is-half-tablet">
              <div class="card">
                <div class="card-image">
                  <figure class="image">
                    <img :src="paso.portada" alt="Placeholder image" />
                  </figure>
                </div>
                <p>{{ paso.titulo }}</p>
              </div>
            </div>
            <div class="column is-one-fifth-desktop is-full-mobile is-full-tablet is-flex is-align-items-center is-justify-content-center">
              <div class="card">
                <div class="process-it">
                  <b-button class="vat-btn is-medium" tag="router-link" :to="{ name: 'espectador-frecuente-tramite' }" type="is-info">{{
                    translatedText('loyalty_process_button')
                  }}</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="google-adds">
      <GoogleAdds />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TheaterCard from './theater-card.vue';
import GoogleAdds from '@/components/Common/google-adds.vue';

export default {
  components: {
    TheaterCard,
    GoogleAdds,
  },
  name: 'Loyalty',
  data() {
    return {};
  },
  computed: {
    ...mapState({
      espectador: (state) => state.dashboard.espectador,
    }),
    loyaltyContent() {
      return this.espectador.find((item) => item.id === '2' || item.ubicacion === 'Espectador Frecuente');
    },
  },
  methods: {},
  mounted() {},
};
</script>
