<template>
  <div></div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'EventScript',
  data() {
    return {
      eventData: null,
    };
  },
  computed: {
    ...mapState({
      event: (state) => state.event.event,
    }),
  },
  methods: {
    ...mapActions('event', ['fetchEvent']),
    async fetchEventData() {
      const previousRoute = localStorage.getItem('previousRoute');
      const match = previousRoute && previousRoute.match(/^\/cartelera\/(\d+)$/);

      if (match) {
        const eventId = match[1];
        console.log(eventId);
        await this.fetchEvent({ evento_id: eventId });
        console.log(this.event);

        if (this.event) {
          this.addJsonLdScript();
        } else {
          console.error('Evento no encontrado');
        }
      } else {
        console.error('No se encontró una ruta anterior válida');
      }
    },
    formatDate(date, time) {
      const dateTime = new Date(`${date}T${time}:00-05:00`);
      return dateTime.toISOString();
    },
    addJsonLdScript() {
      const { event } = this;
      if (!event || !Array.isArray(event.fechas) || event.fechas.length === 0) {
        console.error('El evento o sus fechas no son válidos');
        return;
      }

      const totalDates = event.fechas.length;
      const firstDate = event.fechas[0];
      const lastDate = event.fechas[totalDates - 1];

      if (!firstDate.funciones || !lastDate.funciones || firstDate.funciones.length === 0 || lastDate.funciones.length === 0) {
        console.error('Las funciones del evento no son válidas');
        return;
      }

      const firstFunction = firstDate.funciones[0];
      const lastFunction = lastDate.funciones[0];

      const startDate = this.formatDate(firstDate.fecha, firstFunction.hora);
      const endDate = this.formatDate(lastDate.fecha, lastFunction.hora);

      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.text = JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Event',
        name: event.nombre,
        startDate: startDate,
        endDate: endDate,
        eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
        eventStatus: 'https://schema.org/EventScheduled',
        location: {
          '@type': 'Place',
          name: event.teatro,
          address: {
            '@type': 'PostalAddress',
            streetAddress: event.teatro_direccion,
            addressLocality: event.localidad || 'Unknown',
            postalCode: 'Unknown',
            addressRegion: event.estado,
            addressCountry: 'MX',
          },
        },
        image: [event.portada],
        description: event.sinopsis,
        offers: firstFunction.boletos.map((offer) => ({
          '@type': 'Offer',
          url: event.url || 'https://voyalteatro.com/cartelera/' + event.id,
          price: offer.precio,
          priceCurrency: 'MXN',
          availability: 'https://schema.org/InStock',
          validFrom: 'Unknown',
        })),
        performer: {
          '@type': 'PerformingGroup',
          name: event.presenta,
        },
        organizer: {
          '@type': 'Organization',
          name: event.presenta,
          url: 'https://voyalteatro.com',
        },
      });
      document.head.appendChild(script);
    },
  },
  mounted() {
    this.fetchEventData();
  },
};
</script>
