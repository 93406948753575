<template>
  <div>
    <b-loading :is-full-page="true" :active="is_loading" :can-cancel="false" :fullscreen="true">
      <template #default>
        <div class="has-text-centered">
          <h1 class="title">{{ translatedText('loading') }}</h1>
        </div>
      </template>
    </b-loading>
    <section
      v-if="event && event.activar == 1 && (event.estados_id == selectedState || event.estados_id == 33) && !is_loading"
      class="event-detail-page"
    >
      <div class="event-header">
        <div class="container">
          <div class="columns">
            <div class="column is-3">
              <EventCard :event="event" />
            </div>
            <div class="column is-5">
              <div class="event-info">
                <h2 class="title">{{ event.nombre }}</h2>
                <h2 class="subtitle">{{ event.subtitulo }}</h2>
                <!-- <h3 class="subtitle">subtitulo</h3> -->
                <h3 class="theater has-text-link">{{ event.teatro }}</h3>
                <div class="columns is-multiline">
                  <div class="column is-6">
                    <div class="info-label">{{ translatedText('category') }}</div>
                    <div class="info-value">{{ event.categoria }}</div>
                  </div>
                  <div v-if="event.presenta != ''" class="column is-6">
                    <div class="info-label">{{ translatedText('present') }}</div>
                    <div class="info-value">{{ event.presenta }}</div>
                  </div>
                  <div class="column is-6">
                    <div class="info-label">{{ translatedText('duration') }}</div>
                    <div class="info-value">{{ event.duracion }}</div>
                  </div>
                  <div v-if="event.direccion != ''" class="column is-6">
                    <div class="info-label">{{ translatedText('address') }}</div>
                    <div class="info-value">{{ event.direccion }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div v-if="event.trailer" class="event-trailer">
                <h2 class="title">Trailer</h2>
                <iframe :src="event.trailer" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="event-content">
        <div class="container">
          <div class="columns">
            <div class="column is-three-fifths">
              <div class="event-section-options is-flex">
                <b-button
                  class="is-medium vat-btn"
                  :class="{ 'is-active': selectedOption === 'details' }"
                  @click="selectOption('details')"
                  type="is-info"
                  >{{ translatedText('details') }}</b-button
                >
                <b-button
                  class="is-medium vat-btn"
                  :class="{ 'is-active': selectedOption === 'credits' }"
                  @click="selectOption('credits')"
                  type="is-info"
                  >{{ translatedText('credits') }}</b-button
                >
                <b-button
                  v-if="event.virtual == 0"
                  :class="{ 'is-active': selectedOption === 'theater' }"
                  class="is-medium vat-btn"
                  @click="selectOption('theater')"
                  type="is-info"
                  >{{ translatedText('theater') }}</b-button
                >
                <b-button
                  v-if="event.virtual == 1"
                  :class="{ 'is-active': selectedOption === 'virtual' }"
                  class="is-medium vat-btn"
                  @click="validateVirtualAccess"
                  type="is-info"
                  >{{ translatedText('virtual') }}</b-button
                >
              </div>
              <div class="event-section">
                <!-- Contenido dinámico según el botón presionado -->
                <div v-if="selectedOption === 'details'">
                  <div v-if="event.sinopsis">
                    <h4 class="event-section-title">{{ translatedText('synopsis') }}</h4>
                    <p class="event-section-content white-space">{{ event.sinopsis }}</p>
                  </div>
                  <div v-if="event.boletos">
                    <h4 class="event-section-title">{{ translatedText('important_details') }}</h4>
                    <div v-html="event.boletos" class="event-section-boletos"></div>
                  </div>
                  <div v-if="event.critica_url">
                    <b-button
                      class="is-medium vat-btn event-section-critica"
                      :href="event.critica_url"
                      target="_blank"
                      type="is-info"
                      tag="a"
                      expanded
                      >{{ translatedText('read_review') }}</b-button
                    >
                  </div>
                  <div v-if="event.informacion_importante">
                    <h4 class="event-section-title">{{ translatedText('information') }}</h4>
                    <p class="event-section-content white-space">{{ event.informacion_importante }}</p>
                  </div>
                </div>
                <div v-else-if="selectedOption === 'credits'">
                  <h4 class="event-section-title">{{ translatedText('credits') }}</h4>
                  <div v-if="event.creditos">
                    <p class="event-section-content white-space">{{ event.creditos }}</p>
                  </div>
                </div>
                <div v-else-if="selectedOption === 'theater'">
                  <Theater :theater="event.teatros" :is_loading="false" />
                </div>
                <div v-else-if="selectedOption === 'virtual'">
                  <h4 class="event-section-title">{{ translatedText('virtual_room') }}</h4>
                  <div class="sala-virtual-iframe" v-html="event.sala_virtual"></div>
                </div>
              </div>
              <div v-if="publicidad.length > 0" class="columns is-centered">
                <div class="column is-12">
                  <Advertising />
                </div>
              </div>
            </div>
            <div class="column is-two-fifths">
              <div v-if="event.disponibilidad !== 'agotado'" class="event-payment-form">
                <EventForm></EventForm>
              </div>
              <div v-if="event.video" class="event-trailer">
                <iframe :src="event.video" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="allowfullscreen"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="google-adds">
        <GoogleAdds />
      </section>
    </section>
    <NotFound
      v-if="!is_loading && event && (event.activar == 0 || (event.activar == 1 && event.estados_id != selectedState && event.estados_id != 33))"
      :title="getNotFoundTitle()"
      :subtitle="getNotFoundSubtitle()"
      :url="'/'"
    />
    <NotFound v-if="!is_loading && !event.nombre" :title="translatedText('event_not_found')" />
  </div>
</template>

<script>
import EventCard from './event-card.vue';
import Theater from './theater.vue';
import EventForm from './form.vue';
import NotFound from '../404.vue';
import Advertising from '../../../components/Common/advertising.vue';
import GoogleAdds from '@/components/Common/google-adds.vue';
import Swal from 'sweetalert2';
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'EventDetails',
  components: {
    EventCard,
    EventForm,
    Theater,
    NotFound,
    Advertising,
    GoogleAdds,
  },
  data() {
    return {
      selectedOption: 'details',
      is_loading: true,
    };
  },
  computed: {
    ...mapState({
      event: (state) => state.event.event,
      virtualOrdenes: (state) => state.event.event.virtual_ordenes,
      selectedState: (state) => state.statesCatalog.selectedState,
      publicidad: (state) => state.dashboard.publicidad,
    }),
  },
  methods: {
    ...mapActions('event', ['fetchEvent']),
    selectOption(option) {
      this.selectedOption = option;
    },
    async loadEvent() {
      await this.fetchEvent({ evento_id: this.$route.params.id });
      this.is_loading = false;
    },
    getNotFoundTitle() {
      if (this.event.activar == 0) {
        return this.translatedText('event_finished');
      } else if (this.event.activar == 1 && this.event.estados_id != this.selectedState && this.event.estados_id != 33) {
        return this.translatedText('event_not_found');
      }
      return '';
    },
    getNotFoundSubtitle() {
      if (this.event.activar == 0) {
        return this.translatedText('event_not_available');
      } else if (this.event.activar == 1 && this.event.estados_id != this.selectedState && this.event.estados_id != 33) {
        return `${this.translatedText('event_belongs')} "${this.event.nombre}" ${this.translatedText('event_belongs_1')} ${
          this.event.estado
        } ${this.translatedText('event_belongs_2')}`;
      }
      return '';
    },
    validateVirtualAccess() {
      const fechaActual = moment();
      let ordenValidaEncontrada = false;

      if (!this.virtualOrdenes || this.virtualOrdenes.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Para ingresar debes comprar primero un boleto.',
          confirmButtonColor: '#268af7',
        });
        return false;
      }

      if (this.event.virtual_opciones == 'RANGO') {
        const fechaInicio = this.event.fecha_inicio;
        const fechaFin = this.event.fecha_fin;

        if (fechaActual.isSameOrAfter(fechaInicio) && fechaActual.isSameOrBefore(fechaFin)) {
          this.selectOption('virtual');
          ordenValidaEncontrada = true;
        }
      } else {
        for (const orden of this.virtualOrdenes) {
          const fechaHoraOrden = moment(orden.fecha_del_evento + ' ' + orden.hora);

          if (fechaActual.isAfter(fechaHoraOrden) || fechaHoraOrden.isSame(fechaActual)) {
            this.selectOption('virtual');
            ordenValidaEncontrada = true;
            break;
          }
        }
      }

      if (!ordenValidaEncontrada) {
        Swal.fire({
          icon: 'info',
          title: 'Ya tienes tu boleto para la función que compraste. Te esperamos en el día y hora de presentación de este evento.',
          confirmButtonColor: '#268af7',
        });
      }

      return ordenValidaEncontrada;
    },
    isOldSafari() {
      if (this.event.estatus == '1') {
        return false;
      }
      
      const ua = navigator.userAgent.toLowerCase();
      const isSafari = ua.includes('safari') && !ua.includes('chrome');

      if (isSafari) {
        const safariVersionMatch = ua.match(/version\/(\d+)\./);
        if (safariVersionMatch && safariVersionMatch.length > 1) {
          const safariVersion = parseInt(safariVersionMatch[1], 10);
          return safariVersion < 16;
        }
      }
      return false;
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.loadEvent();
    if (this.isOldSafari()) {
      Swal.fire({
        title: 'Advertencia',
        text: 'Estás utilizando una versión antigua de Safari. Esto pudiera limitar la compra de boletos de este evento. Te recomendamos actualizar tu navegador o utilizar Google Chrome.',
        icon: 'warning',
        confirmButtonText: 'Aceptar',
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      return new Promise((resolve, reject) => {
        vm.loadEvent()
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  },
};
</script>
