
const getters = {
  banners: state => {
    const sortedBanners = [...state.banners].sort((a, b) => a.order_appear - b.order_appear);

    return sortedBanners.map(bannerItem => {
      return {
        ...bannerItem,
        title: bannerItem.nombre,
        image: bannerItem.portada,
      };
    });
  },
  featuredEvents: state => {
    return state.destacados.map(destacadoItem => {
      return {
        ...destacadoItem,
        title: destacadoItem.nombre,
        image: destacadoItem.portada,
        url: '/evento/' + destacadoItem.id,
        target: 'interno'
      };
    });
  },
  blog: state => {
    return state.blog.map(blogItem => {
      return {
        ...blogItem,
        title: blogItem.titulo,
        image: blogItem.portada,
        url: '/blog/' + blogItem.id,
      };
    });
  },
  sectionTop: state => {
    return state.espectador.find(item => item.ubicacion === 'Pagina de inicio');
  },
  sectionBottom: state => {
    return state.contenidos.find(item => item.nombre === 'App');
  },
  boletin: state => {
    return state.contenidos.find(item => item.nombre === 'Boletin');
  },
  contenido: state => {
    return state.contenidos.find(item => item.nombre === 'Boletin');
  },
};

export default getters;
