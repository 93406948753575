import axiosInstance from '../../api/axiosInstance';

const actions = {
  async fetchBilboard({ commit }, queryParams) {
    try {
      const url = '/cartelera';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);

      if (response?.status === 200 && response?.data?.payload) {
        const billboard = response.data.payload.eventos;
        const filters = response.data.payload.filtros;
        const eventDates = response.data.payload.dates;

        if (billboard.length == 0 || billboard.options.page == 1) {
          commit('INIT_STATE');
        }

        commit('ADD_TO_LIST', { key: 'list', data: billboard.list || [] });
        commit('SET_DATA', { key: 'options', data: billboard.options || [] });
        commit('SET_DATA', { key: 'filters', data: filters || [] });
        commit('SET_DATA', { key: 'eventDates', data: eventDates || [] });
      } else {
        throw new Error('No se pudo obtener la información de la cartelera');
      }
    } catch (error) {
      console.error('Error al obtener la cartelera:', error);
    }
  },
  async fetchSearchList({ commit }, queryParams) {
    try {
      const url = '/cartelera/search';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);

      if (response?.status === 200 && response?.data?.payload) {
        const events = response.data.payload.eventos;
        commit('SET_DATA', { key: 'searchList', data: events || [] });
      } else {
        throw new Error('No se pudo obtener la información de la cartelera');
      }
    } catch (error) {
      console.error('Error al obtener la cartelera:', error);
    }
  },
};

export default actions;