import SelectedState from '@/modules/Root/selectedState.vue';

const routes = [
  {
    path: '/aguascalientes',
    component: SelectedState,
    name: 'aguascalientes',
  },
  {
    path: '/bajacalifornia',
    component: SelectedState,
    name: 'bajacalifornia',
  },
  {
    path: '/bajacaliforniasur',
    component: SelectedState,
    name: 'bajacaliforniasur',
  },
  {
    path: '/campeche',
    component: SelectedState,
    name: 'campeche',
  },
  {
    path: '/chiapas',
    component: SelectedState,
    name: 'chiapas',
  },
  {
    path: '/chihuahua',
    component: SelectedState,
    name: 'chihuahua',
  },
  {
    path: '/cdmx',
    component: SelectedState,
    name: 'ciudaddemexico',
  },
  {
    path: '/coahuila',
    component: SelectedState,
    name: 'coahuila',
  },
  {
    path: '/colima',
    component: SelectedState,
    name: 'colima',
  },
  {
    path: '/durango',
    component: SelectedState,
    name: 'durango',
  },
  {
    path: '/edomex',
    component: SelectedState,
    name: 'estadodemexico',
  },
  {
    path: '/guanajuato',
    component: SelectedState,
    name: 'guanajuato',
  },
  {
    path: '/guerrero',
    component: SelectedState,
    name: 'guerrero',
  },
  {
    path: '/hidalgo',
    component: SelectedState,
    name: 'hidalgo',
  },
  {
    path: '/jalisco',
    component: SelectedState,
    name: 'jalisco',
  },
  {
    path: '/michoacan',
    component: SelectedState,
    name: 'michoacan',
  },
  {
    path: '/morelos',
    component: SelectedState,
    name: 'morelos',
  },
  {
    path: '/nayarit',
    component: SelectedState,
    name: 'nayarit',
  },
  {
    path: '/nuevoleon',
    component: SelectedState,
    name: 'nuevoleon',
  },
  {
    path: '/oaxaca',
    component: SelectedState,
    name: 'oaxaca',
  },
  {
    path: '/puebla',
    component: SelectedState,
    name: 'puebla',
  },
  {
    path: '/queretaro',
    component: SelectedState,
    name: 'queretaro',
  },
  {
    path: '/quintanaroo',
    component: SelectedState,
    name: 'quintanaroo',
  },
  {
    path: '/sanluispotosi',
    component: SelectedState,
    name: 'sanluispotosi',
  },
  {
    path: '/sinaloa',
    component: SelectedState,
    name: 'sinaloa',
  },
  {
    path: '/sonora',
    component: SelectedState,
    name: 'sonora',
  },
  {
    path: '/tabasco',
    component: SelectedState,
    name: 'tabasco',
  },
  {
    path: '/tamaulipas',
    component: SelectedState,
    name: 'tamaulipas',
  },
  {
    path: '/tlaxcala',
    component: SelectedState,
    name: 'tlaxcala',
  },
  {
    path: '/veracruz',
    component: SelectedState,
    name: 'veracruz',
  },
  {
    path: '/yucatan',
    component: SelectedState,
    name: 'yucatan',
  },
  {
    path: '/zacatecas',
    component: SelectedState,
    name: 'zacatecas',
  },
];

export default routes;