const initialState = {
  event: [],
  cupon: false,
};

const mutations = {
  SET_DATA(state, { key, data }) {
    state[key] = data;
  },
  INIT_STATE(state) {
    state.list = initialState.list;
    state.options = { ...initialState.options };
  },
};

export default mutations;
