<template>
  <div class="ticket-scan">
    <div class="modal" :class="{ 'is-active': ticketsModalSelect }">
      <div class="modal-background"></div>
      <div class="modal-card tickets-modal-size">
        <header class="modal-card-head">
          <p class="modal-card-title">Selecciona los boletos para su asistencia</p>
          <button @click="closeModalTickets" class="delete" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <div v-if="ticketsToRegister && ticketsToRegister.boletos">
            <b-table class="modal-tickets-scan" :data="ticketsToRegister.boletos" :mobile-cards="false">
              <b-table-column field="checked" label="" v-slot="props">
                <b-checkbox v-model="props.row.checked" native-value="INGRESADO"></b-checkbox>
              </b-table-column>

              <b-table-column field="nombre" label="Entrada" v-slot="props">
                {{ props.row.nombre }}
              </b-table-column>

              <b-table-column field="asiento" label="Asiento" v-slot="props">
                {{ props.row.asiento }}
              </b-table-column>

              <b-table-column field="estatus" label="Estatus" v-slot="props">
                <b-tag v-if="props.row.estatus == 'INGRESADO'" type="is-success" rounded>&nbsp;&nbsp;</b-tag>
                <b-tag v-else type="is-danger" rounded>&nbsp;&nbsp;</b-tag>
              </b-table-column>

              <template #empty>
                <div class="has-text-centered">No records</div>
              </template>
            </b-table>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button @click="closeModalTickets" class="button">Cancelar</button>
          <button @click="updateTickets" class="button is-info">Actualizar</button>
        </footer>
      </div>
    </div>
    <div class="container">
      <div class="ticket-scan-info">
        <div class="columns is-multiline is-vcentered">
          <div class="column is-6">
            <div id="reader"></div>
          </div>
          <div class="column is-6">
            <div class="scan-event-info">
              <h2 class="title">{{ statistics?.nombre }}</h2>
              <h3 class="teather has-text-link">{{ statistics?.teatro }}</h3>
              <div class="columns is-multiline">
                <div class="column is-6">
                  <div class="info-label">Fecha</div>
                  <div class="info-value">{{ formatDate(fechaInfo?.fecha) }}</div>
                </div>
                <div class="column is-6">
                  <div class="info-label">Función</div>
                  <div class="info-value">{{ fechaInfo?.funcion?.hora }} h</div>
                </div>
                <div class="column is-6">
                  <div class="info-label">Vendidos</div>
                  <div class="info-value">{{ vendidos }}</div>
                </div>
                <div class="column is-6">
                  <div class="info-label">Ingresados</div>
                  <div class="info-value">{{ ingresados }}</div>
                </div>
                <div class="column is-12">
                  <div class="info-label">Por Ingresar</div>
                  <div class="info-value">{{ por_ingresar }}</div>
                </div>
                <div class="column is-12">
                  <div class="check-manual has-text-centered">
                    <b-field label="Ingreso manual">
                      <b-input placeholder="Código del boleto" v-model="manualCode" rounded></b-input>
                    </b-field>
                    <b-button type="is-info" rounded expanded :disabled="manualCodeDisabled" @click="sendManualCode">Enviar</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import moment from 'moment';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { mapActions, mapState, mapGetters } from 'vuex';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      manualCode: null,
      decodedText: '',
      scanning: false,
      html5QrcodeScanner: null,
      ticketsModalSelect: false,
      ticketsToRegister: [],
      manualCodeDisabled: false,
      ticketsModalSelectManual: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.startScanner();
  },
  computed: {
    ...mapState({
      statistics: (state) => state.event.statistics,
    }),
    ...mapGetters('event', ['fechaData']),
    code() {
      return this.$route.params.code || null;
    },
    funcion_id() {
      return this.$route.params.funcion || null;
    },
    fechaInfo() {
      if (this.funcion_id !== null) {
        return this.fechaData(this.funcion_id);
      } else {
        return null;
      }
    },
    vendidos() {
      if (this.fechaInfo && this.fechaInfo.funcion.boletos) {
        return this.fechaInfo.funcion.boletos.reduce((totalVendidos, boleto) => {
          const cantidadVendida = parseInt(boleto.vendidos);
          return totalVendidos + cantidadVendida;
        }, 0);
      } else {
        return 0;
      }
    },
    por_ingresar() {
      if (this.fechaInfo && this.fechaInfo.funcion.ordenes) {
        return this.fechaInfo.funcion.ordenes.reduce((totalPorIngresar, orden) => {
          if (orden.estatus === 'NO_INGRESADO') {
            return totalPorIngresar + 1;
          } else {
            return totalPorIngresar;
          }
        }, 0);
      } else {
        return 0;
      }
    },
    ingresados() {
      if (this.fechaInfo && this.fechaInfo.funcion.ordenes) {
        return this.fechaInfo.funcion.ordenes.reduce((totalIngresados, orden) => {
          if (orden.estatus === 'INGRESADO') {
            return totalIngresados + 1;
          } else {
            return totalIngresados;
          }
        }, 0);
      } else {
        return 0;
      }
    },
  },
  methods: {
    ...mapActions('event', ['fetchStatistics', 'fetchQrTickets', 'updateTicketsFromQr']),
    async loadStatistics() {
      await this.fetchStatistics({ code: this.code });
      this.is_loading = false;
    },
    async qrTickets(code) {
      const ticketsResponse = await this.fetchQrTickets({ code: code, funciones_id: this.funcion_id });

      if (ticketsResponse && !ticketsResponse.status) {
        Swal.fire({
          icon: ticketsResponse.class,
          title: ticketsResponse.msg,
          confirmButtonColor: '#268af7',
        });
      }

      if (ticketsResponse.status == true) {
        this.ticketsToRegister = ticketsResponse.payload;

        this.ticketsToRegister.boletos.forEach((boleto) => {
          boleto.checked = true;
        });

        this.ticketsModalSelect = true;
      } else {
        setTimeout(() => {
          this.scanResume();
        }, 2000); // 2000 milisegundos = 2 segundos
      }
    },
    initQrCodeScanner() {
      this.html5QrcodeScanner = new Html5QrcodeScanner('reader', { fps: 10, qrbox: { width: 300, height: 300 } }, false);

      this.html5QrcodeScanner.render(this.onScanSuccess, this.onScanFailure);
    },
    async onScanSuccess(decodedText) {
      console.log(decodedText);
      // Verificar si el código escaneado es un string
      if (typeof decodedText === 'string') {
        this.decodedText = decodedText;
        this.qrTickets(decodedText);
        this.scanPause();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Código no válido',
          text: 'El código escaneado no corresponde a un boleto válido de VOY AL TEATRO.',
          confirmButtonColor: '#268af7',
        });
      }
    },
    scanPause() {
      this.html5QrcodeScanner.pause();
    },
    scanResume() {
      this.html5QrcodeScanner.resume();
    },
    scanClear() {
      this.html5QrcodeScanner.clear();
    },
    onScanFailure(error) {
      return error;
      //console.warn(`Code scan error = ${error}`);
    },
    async startScanner() {
      await this.initQrCodeScanner();
      // Cambiar la imagen después de 5 segundos
      setTimeout(() => {
        const imageElement = document.querySelector('#reader__scan_region img');
        if (imageElement) {
          imageElement.src = 'https://res.cloudinary.com/peter-white/image/upload/v1666649836/qr_phone_2cb506afee.png';
        }
      }, 300); // 5000 milisegundos = 5 segundos
    },
    async sendManualCode() {
      this.manualCodeDisabled = true;

      if (!this.manualCode) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Por favor, ingresa un codigo del boleto.',
        });

        this.manualCodeDisabled = false;
        return;
      }

      const ticketsResponse = await this.fetchQrTickets({ code: this.manualCode, funciones_id: this.funcion_id });

      if (ticketsResponse && !ticketsResponse.status) {
        Swal.fire({
          icon: ticketsResponse.class,
          title: ticketsResponse.msg,
          confirmButtonColor: '#268af7',
        });
        this.manualCodeDisabled = false;
        return;
      }

      if (ticketsResponse.status == true) {
        this.ticketsToRegister = ticketsResponse.payload;

        this.ticketsToRegister.boletos.forEach((boleto) => {
          boleto.checked = true;
        });

        this.ticketsModalSelect = true;
        this.ticketsModalSelectManual = true;
      }

      this.manualCodeDisabled = false;
    },
    formatDate(date) {
      if (!date) {
        return '';
      }

      moment.locale('es');
      let fechaFormateada = moment(date).format('dddd D [de] MMMM [de] YYYY');
      fechaFormateada = `${fechaFormateada.charAt(0).toUpperCase() + fechaFormateada.slice(1)}`;
      return fechaFormateada;
    },
    async updateTickets() {
      const boletos_update = this.ticketsToRegister.boletos.map(({ id, nombre, asiento, checked }) => ({
        id,
        nombre,
        asiento,
        estatus: checked ? 'INGRESADO' : 'NO_INGRESADO',
      }));

      const response = await this.updateTicketsFromQr({ boletos: boletos_update });

      if (response) {
        Swal.fire({
          icon: 'success',
          title: 'Boletos actualizados correctamente',
          confirmButtonColor: '#268af7',
        });

        await this.loadStatistics();
        this.ticketsModalSelect = false;
        this.ticketsToRegister = [];
        if (!this.ticketsModalSelectManual) {
          this.scanResume();
        }
        this.ticketsModalSelectManual = false;
        this.manualCode = null;
      }
    },
    closeModalTickets() {
      this.ticketsModalSelect = false;
      this.ticketsToRegister = [];
      this.scanResume();
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadStatistics();
    });
  },
  beforeRouteLeave(to, from, next) {
    // Ejecuta tu método antes de salir del componente
    this.scanClear();
    next(); // Continúa con la navegación
  },
};
</script>

<style>
#html5-qrcode-anchor-scan-type-change {
  display: none !important;
}
#reader {
  border: none !important;
}
#html5-qrcode-button-camera-start,
#html5-qrcode-button-camera-stop {
  border-radius: 9999px;
  padding-left: calc(1em + 0.25em);
  padding-right: calc(1em + 0.25em);
  display: flex;
  width: 100%;
  background-color: #268af7;
  border-color: transparent;
  color: #fff;
  border-width: 1px;
  cursor: pointer;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  font-size: 1rem;
  height: 2.5em;
  margin: 20px auto;
}
#reader__scan_region img {
  opacity: 1 !important;
  width: 50%;
}
.check-manual {
  margin: 10px 0;
}
.check-manual .label {
  color: #fff;
}
.check-manual .input {
  margin-bottom: 10px;
}

@media screen and (max-width: 1023px) {
  .ticket-scan .modal .tickets-modal-size {
    width: 95%;
    height: 95%;
    max-width: none;
    max-height: none;
  }

  .ticket-scan .modal-card-head {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}
</style>
