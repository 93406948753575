// languagePlugin.js

import { getLanguage, setLanguage } from './lang'; // Importa las funciones de lang.js

const languagePlugin = {
  install(Vue) {
    Vue.mixin({
      computed: {
        currentLanguage() {
          return getLanguage(); // Obtiene el idioma actual del almacenamiento local
        }
      },
      methods: {
        changeLanguage(lang) {
          setLanguage(lang); // Establece el idioma en el almacenamiento local
        },
        translatedText(textKey) {
          const translations = require(`@/locales/${this.currentLanguage}.json`); // Importa las traducciones según el idioma actual
          return translations[textKey]; // Devuelve el texto traducido
        }
      }
    });
  }
};

export default languagePlugin;
