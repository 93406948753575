<template>
  <b-carousel class="advertising" :autoplay="true" :indicator="false" :arrow="false" :animated="'fade'" :interval="8000">
    <b-carousel-item v-for="(item, i) in randomizedPublicidad" :key="i">
      <template>
        <a :href="item.url" target="_blank" rel="noopener noreferrer">
          <b-image v-once class="image" :src="item.portada" role="button" tabindex="0"></b-image>
        </a>
      </template>
    </b-carousel-item>
  </b-carousel>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      publicidad: (state) => state.dashboard.publicidad,
    }),
    randomizedPublicidad() {
      return this.shuffleArray(this.publicidad);
    },
  },
  methods: {
    shuffleArray(array) {
      let shuffledArray = array.slice();
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
      }
      return shuffledArray;
    },
  },
};
</script>

<style>
.advertising .image {
  animation: fadeIn;
  animation-duration: 1s;
}
.advertising .image img {
  border-radius: 20px !important;
}
.advertising .carousel-pause {
  display: none;
}
</style>
