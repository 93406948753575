import { loadStripe } from '@stripe/stripe-js';
import axiosInstance from '../../api/axiosInstance';
import Swal from 'sweetalert2';

const actions = {
  async initializeStripe({ commit }) {
    const stripeKey = 'pk_live_Oa2dVM0QscB0K3tsEKW5MiLD';
    //const stripeKey = 'pk_test_4xLBbh71FPvVY56Zm25I8Dnz';
    const stripeInstance = await loadStripe(stripeKey);
    commit('setStripeInstance', stripeInstance);
  },
  async createPaymentToken({ commit, state }, { cardNumber, cardOwner, addressZip }) {
    const stripe = state.stripe;
    if (!stripe) {
      throw new Error('Stripe is not initialized');
    }

    const { token, error } = await stripe.createToken(cardNumber, {
      name: cardOwner,
      address_zip: addressZip,
      address_country: 'MX',
      currency: 'MXN',
    });

    if (error) {
      throw new Error(error.message);
    } else {
      commit('setPaymentToken', token);
    }
  },
  async confirmOxxoPaymentWithIntent({ commit, state }, { client_secret, billing_details }) {
    const stripe = state.stripe;
    if (!stripe) {
      throw new Error('Stripe is not initialized');
    }

    const { error, paymentIntent } = await stripe.confirmOxxoPayment(
      client_secret,
      {
        payment_method: {
          billing_details: {
            name: billing_details.name,
            email: billing_details.email,
          },
        }
      },
      {
        handleActions: false,
      }
    );

    if (error) {
      throw new Error(error.message);
    } else {
      commit('setPaymentIntent', paymentIntent);
      return paymentIntent;
    }
  },
  async submitCardOrder({ commit }, orderData) {
    try {
      const url = '/ordenes/buy_card';
      const response = await axiosInstance.post(url, orderData);

      if (response?.status === 200) {
        const status = response.data.status;
        const data = response.data;

        if (status) {
          const order = response.data.payload.order;
          commit('SET_DATA', { key: 'order', data: order || false });
        }
        
        return data;
      } else {
        throw new Error('No se pudo obtener la información de la orden');
      }
    } catch (error) {
      console.error('Error al crear la orden:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error al crear la orden. Por favor, inténtalo de nuevo más tarde.',
        confirmButtonColor: '#268af7',
      });
      throw error; // Propagar el error para que el llamador pueda manejarlo
    }
  },
};

export default actions;