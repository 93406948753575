const mutations = {
  SET_DATA(state, { key, data }) {
    state[key] = data;
  },
  ADD_TO_LIST(state, newData) {
    state.list = [...state.list, ...newData.data];
  },
};

export default mutations;
