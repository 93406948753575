const state = {
  banners: [],
  blog: [],
  destacados: [],
  contenidos: [],
  espectador: [],
  enlaces: [],
  publicidad: [],
  avisos: [],
};

export default state;
