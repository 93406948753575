const initialState = {
  list: [],
  options: {
    estados_id: null,
    limit: 20,
    current_page: 1,
    totalPages: 1,
    total: 20
  },
};

const mutations = {
  SET_DATA(state, { key, data }) {
    state[key] = data;
  },
  
  INIT_STATE(state) {
    state.list = initialState.list;
    state.options = { ...initialState.options };
  },

  ADD_TO_LIST(state, newData) {
    state.list = [...state.list, ...newData.data];
  },
};

export default mutations;
