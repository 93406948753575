import axiosInstance from '../../api/axiosInstance';
import Swal from 'sweetalert2';

const actions = {
  async fetchEvent({ commit }, queryParams) {
    try {
      const url = '/eventos';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);

      if (response?.status === 200 && response?.data?.payload) {
        const evento = response.data.payload.evento;
        commit('SET_DATA', { key: 'event', data: evento || [] });
      } else {
        throw new Error('No se pudo obtener la información de la cartelera');
      }
    } catch (error) {
      console.error('Error al obtener la cartelera:', error);
    }
  },
  async fetchSearchList({ commit }, queryParams) {
    try {
      const url = '/cartelera/search';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);

      if (response?.status === 200 && response?.data?.payload) {
        const events = response.data.payload.eventos;
        commit('SET_DATA', { key: 'searchList', data: events || [] });
      } else {
        throw new Error('No se pudo obtener la información de la cartelera');
      }
    } catch (error) {
      console.error('Error al obtener la cartelera:', error);
    }
  },
  async fetchCupon({ commit }, orderData) {
    try {
      const url = '/cupones/get_by_name';
      const response = await axiosInstance.post(url, orderData);

      if (response?.status == 200) {
         const status = response.data.status;
         const data = response.data;

         if (status) {
           const cupon = response.data.payload.cupon;
           commit('SET_DATA', { key: 'cupon', data: cupon || false });
         }

         return data;
      } else {
        throw new Error('No se pudo obtener la información del cupon');
      }
    } catch (error) {
      console.error('Error al obtener el cupon:', error);
    }
  },
  async submitOrder({ commit }, orderData) {
    try {
      const url = '/ordenes/event';
      const response = await axiosInstance.post(url, orderData);

      if (response?.status === 200) {
        const data = response.data;

        if (data.status) {
          const order = response.data.payload.order;
          commit('SET_DATA', { key: 'order', data: order || false });
        }

        return data;
      } else {
        throw new Error('No se pudo obtener la información de la orden');
      }
    } catch (error) {
      console.error(error);
      return false; // Devolver false en caso de error
    }
  },
  async fetchStatistics({ commit }, queryParams) {
    try {
      const url = '/eventos/statistics';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);

      if (response?.status === 200 && response?.data?.payload) {
        const estadisticas = response.data.payload.estadisticas;
        commit('SET_DATA', { key: 'statistics', data: estadisticas || [] });
      } else {
        throw new Error('No se pudo obtener la información del evento');
      }
    } catch (error) {
      console.error('Error al obtener la cartelera:', error);
    }
  },
  async fetchQrTickets({ commit }, queryParams) {
    try {
      const url = '/qr/get';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);
      if (response?.status === 200) {
        const boletos = response.data.payload;
        const data = response.data;
        commit('SET_DATA', { key: 'qrTickets', data: boletos || [] });
        return data;
      } else {
        throw new Error('No se pudo obtener la información del evento');
      }
    } catch (error) {
      // Mostrar alerta de error genérico
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error al obtener la información del evento',
        confirmButtonColor: '#268af7',
      });
      console.error('Error al obtener la cartelera:', error);
      throw error; // Lanza el error para que sea manejado por el código que llama a esta función
    }
  },
  async updateTicketsFromQr({ commit }, tickets) {
    try {
      const url = '/qr/change_estatus';
      const response = await axiosInstance.post(url, tickets);

      if (response?.status === 200) {
        if (response?.data?.status) {
          const data = response.data;
          commit('SET_DATA', { key: 'qrTickets', data: [] });
          return data;
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error al actualizar los boletos',
            text: response.data.msg || 'No se pudo actualizar los boletos. Por favor, inténtalo de nuevo más tarde.',
            confirmButtonColor: '#268af7',
          });
          return false;
        }
      } else {
        throw new Error('No se pudo obtener la información de la orden');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error de conexión',
        text: 'Hubo un problema al conectarse al servidor. Por favor, inténtalo de nuevo más tarde.',
        confirmButtonColor: '#268af7',
      });
      return false;
    }
  },
  async setPLaces({ commit }, places) {
    try {

      console.log(places);

      const url = '/asientos/reserve';
      const response = await axiosInstance.post(url, places);
      commit('SET_DATA', { key: 'reservePlaces', data: [] });

      if (response?.status === 200) {
        const data = response.data;
        return data;
      } else {
        throw new Error('No se pudo reservar los asientos');
      }
    } catch (error) {
      console.error('Error al crear la orden:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error al crear la orden. Por favor, inténtalo de nuevo más tarde.',
        confirmButtonColor: '#268af7',
      });
      throw error;
    }
  },
};

export default actions;