<template>
  <section class="selected-state-page">
    <div class="container">
      <div class="columns is-centered">
        <div class="column">
          <h2 class="title">Te damos la bienvenida a</h2>
          <img src="https://voyalteatro.mx/v.1.0.0/media/_admin/images/headervat.png" alt="VAT" />
          <h2 class="title">¿En cuál estado quieres ir al teatro? 😍🇲🇽</h2>
          <b-field class="location-menu has-text-centered">
            <b-select v-model="selectedStateInput" @input="updateSelectedState" placeholder="ESTADOS" rounded>
              <option v-for="state in statesCatalog" :key="state.id" :value="state.id">
                {{ state.estado.toUpperCase() }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
    </div>
    <EventScript />
  </section>
</template>

<script>
import EventScript from './eventScript.vue';

export default {
  components: {
    EventScript,
  },
  data() {
    return {
      selectedStateInput: localStorage.getItem('selectedState') || null,
      statesCatalog: [
        {
          id: '34',
          estado: 'Aguascalientes',
          url: 'Aguascalientes',
          siglas: 'AGS',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '2',
          estado: 'Baja California',
          url: 'BajaCalifornia',
          siglas: 'BCN',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '3',
          estado: 'Baja California Sur',
          url: 'BajaCaliforniaSur',
          siglas: 'BCS',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '4',
          estado: 'Campeche',
          url: 'Campeche',
          siglas: 'CAM',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '7',
          estado: 'Chiapas',
          url: 'Chiapas',
          siglas: 'CHP',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '8',
          estado: 'Chihuahua',
          url: 'Chihuahua',
          siglas: 'CHI',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '9',
          estado: 'Ciudad de México',
          url: 'cdmx',
          siglas: 'DF',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '5',
          estado: 'Coahuila',
          url: 'Coahuila',
          siglas: 'COH',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '6',
          estado: 'Colima',
          url: 'Colima',
          siglas: 'COL',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '10',
          estado: 'Durango',
          url: 'Durango',
          siglas: 'DUR',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '38',
          estado: 'Estado de México',
          url: 'edomex',
          siglas: '',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '11',
          estado: 'Guanajuato',
          url: 'Guanajuato',
          siglas: 'GUA',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '12',
          estado: 'Guerrero',
          url: 'Guerrero',
          siglas: 'GUE',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '13',
          estado: 'Hidalgo',
          url: 'Hidalgo',
          siglas: 'HID',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '14',
          estado: 'Jalisco',
          url: 'Jalisco',
          siglas: 'JAL',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '16',
          estado: 'Michoacán',
          url: 'Michoacán',
          siglas: 'MIC',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '17',
          estado: 'Morelos',
          url: 'Morelos',
          siglas: 'MOR',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '18',
          estado: 'Nayarit',
          url: 'Nayarit',
          siglas: 'NAY',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '19',
          estado: 'Nuevo León',
          url: 'NuevoLeón',
          siglas: 'NVL',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '20',
          estado: 'Oaxaca',
          url: 'Oaxaca',
          siglas: 'OAX',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '21',
          estado: 'Puebla',
          url: 'Puebla',
          siglas: 'PUE',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '22',
          estado: 'Querétaro',
          url: 'Querétaro',
          siglas: 'QRO',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '23',
          estado: 'Quintana Roo',
          url: 'QuintanaRoo',
          siglas: 'QR',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '24',
          estado: 'San Luis Potosí',
          url: 'SanLuisPotosí',
          siglas: 'SLP',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '25',
          estado: 'Sinaloa',
          url: 'Sinaloa',
          siglas: 'SIN',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '26',
          estado: 'Sonora',
          url: 'Sonora',
          siglas: 'SON',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '27',
          estado: 'Tabasco',
          url: 'Tabasco',
          siglas: 'TAB',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '28',
          estado: 'Tamaulipas',
          url: 'Tamaulipas',
          siglas: 'TAM',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '29',
          estado: 'Tlaxcala',
          url: 'Tlaxcala',
          siglas: 'TLA',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '30',
          estado: 'Veracruz',
          url: 'Veracruz',
          siglas: 'VER',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '31',
          estado: 'Yucatán',
          url: 'Yucatán',
          siglas: 'YUC',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '37',
          estado: 'Zacatecas',
          url: 'Zacatecas',
          siglas: '',
          paises_id: '0',
          estatus: '1',
        },
        {
          id: '36',
          estado: '*Otros países',
          url: '*Otros países',
          siglas: '',
          paises_id: '0',
          estatus: '1',
        },
      ],
    };
  },
  mounted() {
    this.checkAndUpdateSelectedState();
  },
  methods: {
    updateSelectedState() {
      const selectedStateNumber = parseInt(this.selectedStateInput);

      if (!isNaN(selectedStateNumber)) {
        this.$store.commit('statesCatalog/SET_DATA', { key: 'selectedState', data: selectedStateNumber });
        localStorage.setItem('selectedState', selectedStateNumber.toString());

        const previousRoute = localStorage.getItem('previousRoute');

        if (previousRoute !== '') {
          window.location.href = previousRoute;
        }

        if (this.$route.path === '/') {
          //window.location.reload();
        } else {
          this.$router.push({ path: '/' }).then(() => {
            //window.location.reload();
          });
        }
      }
    },
    checkAndUpdateSelectedState() {
      const currentPath = this.$route.path.toLowerCase();
      const foundState = this.statesCatalog.find(
        (state) =>
          state.url
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') === currentPath.replace(/^\//, '').toLowerCase(),
      );

      if (foundState) {
        const selectedStateNumber = parseInt(foundState.id);
        this.$store.commit('statesCatalog/SET_DATA', { key: 'selectedState', data: selectedStateNumber });
        localStorage.setItem('selectedState', selectedStateNumber.toString());
        if (this.$route.path === '/') {
          window.location.reload();
        } else {
          this.$router.push({ path: '/' }).then(() => {
            window.location.reload();
          });
        }
      }
    },
  },
};
</script>