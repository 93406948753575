// Reglas de navegación
const navigationRules = {
  // Verifica si el estado selectedState es null y redirige a la ruta selected-state si es necesario
  async requireSelectedState(to, from, next) {
    if (!localStorage.getItem('selectedState')) {
      try {
        // Verificar nuevamente después de actualizar el estado
        if (!localStorage.getItem('selectedState')) {
          localStorage.setItem('previousRoute', to.path);
          console.log(to.path);

          next({ name: 'selected-state' });
        } else {
          next();
        }
      } catch (error) {
        console.error('Error al obtener el estado seleccionado:', error);
        // Manejar el error si ocurre
        next({ name: 'selected-state' }); // Redirigir a la ruta selected-state en caso de error
      }
    } else {
      next();
    }
  },
  // Agrega más reglas de navegación según sea necesario
};

export default navigationRules;
