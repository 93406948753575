import axiosInstance from '../../api/axiosInstance';

const actions = {
  async fetchEstados({ commit }) {
    try {
      const response = await axiosInstance.get('/estados');

      if (response?.status === 200 && response?.data?.payload?.estados) {
        const statesCatalog = response.data.payload.estados;
        commit('SET_DATA', { key: 'statesCatalog', data: statesCatalog });
      } else {
        throw new Error('No se pudo obtener la lista de estados');
      }
    } catch (error) {
      console.error('Error al obtener los estados:', error);
      // Aquí puedes manejar el error de una manera más específica si es necesario
      // Por ejemplo, podrías mostrar un mensaje de error en la interfaz de usuario
    }
  },
};

export default actions;
