<template>
  <section class="home-pages-mensajes-contact">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-12">
          <h2 class="title">Contacto</h2>
          <p class="subtitle">Dudas, comentarios ó reportes</p>
          <form @submit.prevent="sendEmailContact" class="contact-form">
            <div class="columns is-multiline is-centered">
              <div class="column is-6">
                <div class="columns is-multiline">
                  <div class="column is-6">
                    <b-field label="Nombre" :message="nombreError">
                      <b-input v-model="contactData.nombre" type="text" placeholder="Nombre"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Asunto" :message="asuntoError">
                      <b-input v-model="contactData.asunto" type="text" placeholder="Asunto"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Correo" :message="emailError">
                      <b-input v-model="contactData.email" type="email" placeholder="Correo"></b-input>
                    </b-field>
                  </div>
                  <div class="column is-6">
                    <b-field label="Confirmar correo" :message="confirmEmailError">
                      <b-input v-model="contactData.confirmEmail" type="email" placeholder="Confirmar correo"></b-input>
                    </b-field>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <b-field label="Escribe tu mensaje" :message="contactData.mensajeError">
                  <b-input v-model="contactData.mensaje" maxlength="200" type="textarea" placeholder="Escribe tu mensaje"></b-input>
                </b-field>
              </div>
              <div class="column is-6">
                <b-button @click="sendEmailContact" type="is-info" expanded>Enviar</b-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      contactData: {
        nombre: null,
        asunto: null,
        email: null,
        confirmEmail: null,
        mensaje: null,
      },
      nombreError: null,
      asuntoError: null,
      emailError: null,
      confirmEmailError: null,
      mensajeError: null,
    };
  },
  methods: {
    ...mapActions({
      sendContactData: 'auth/sendContactData',
    }),
    clearErrors() {
      // Limpiar los mensajes de error
      this.nombreError = null;
      this.asuntoError = null;
      this.emailError = null;
      this.confirmEmailError = null;
      this.mensajeError = null;
    },
    async sendEmailContact() {
      // Limpiar los mensajes de error anteriores
      this.clearErrors();

      // Validar que se hayan ingresado todas las contraseñas
      if (!this.contactData.nombre) {
        this.nombreError = 'Por favor, ingresa tu nombre.';
      } else if (!this.contactData.asunto) {
        this.asuntoError = 'Por favor, ingresa tu asunto.';
      } else if (!this.contactData.email) {
        this.emailError = 'Por favor, ingresa tu correo.';
      } else if (this.contactData.email !== this.contactData.confirmEmail) {
        // Validar que la nueva contraseña y la confirmación coincidan
        this.confirmEmailError = 'Las correos no coinciden.';
      } else if (!this.contactData.mensaje) {
        this.mensajeError = 'Por favor, ingresa tu mensaje.';
      } else {
        // Si todos los campos son válidos, cambiar la contraseña
        Swal.fire({
          icon: 'info',
          title: 'Cargando...',
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
        });

        const response = await this.sendContactData(this.contactData);

        Swal.close();

        Swal.fire({
          icon: response.class,
          title: 'Contacto',
          text: response.msg,
          confirmButtonColor: '#268af7',
        });

        if (response.status) {
          this.clearForm();
        }
      }
    },
    clearForm() {
      this.contactData.nombre = null;
      this.contactData.asunto = null;
      this.contactData.email = null;
      this.contactData.confirmEmail = null;
      this.contactData.mensaje = null;
    },
  },
};
</script>
