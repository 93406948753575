import axiosInstance from '../../api/axiosInstance';
import Swal from 'sweetalert2';

const actions = {
  async loginUser({ commit }, data) {
    try {
      const url = '/usuarios/login';
      const response = await axiosInstance.post(url, data);
      console.log(data);

      if (response?.status === 200) {
        const status = response.data.status;
        const payload = response.data.payload;
        const data = response.data;

        if (status) {
          commit('SET_DATA', { key: 'usuario', data: payload });
          commit('SET_DATA', { key: 'token', data: payload.token });
          commit('SET_DATA', { key: 'token_sesion', data: payload.token_sesion });
        }

        return data;
      } else {
        throw new Error('No se pudo obtener la información del login');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error de conexión',
        text: 'Hubo un problema al conectarse al servidor. Por favor, inténtalo de nuevo más tarde.',
        confirmButtonColor: '#268af7',
      });
      return false;
    }
  },
  async registerUser({ commit }, data) {
    try {
      const url = '/usuarios/register';
      const response = await axiosInstance.post(url, data);

      if (response?.status === 200) {
        const status = response.data.status;
        const msg = response.data.msg;
        const payload = response.data.payload;

        if (response?.data?.status) {
          if (!status) {
            Swal.fire({
              icon: 'error',
              title: msg,
              confirmButtonColor: '#268af7',
            });
            return false;
          } else {
            Swal.fire({
              icon: 'success',
              title: msg,
              confirmButtonColor: '#268af7',
            });
            commit('SET_DATA', { key: 'usuario', data: payload });
            commit('SET_DATA', { key: 'token', data: payload.token });
            commit('SET_DATA', { key: 'token_sesion', data: payload.token_sesion });
            return payload;
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: msg,
            confirmButtonColor: '#268af7',
          });
          return false;
        }
      } else {
        throw new Error('No se pudo obtener la información del registro');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error de conexión',
        text: 'Hubo un problema al conectarse al servidor. Por favor, inténtalo de nuevo más tarde.',
        confirmButtonColor: '#268af7',
      });
      return false;
    }
  },
  async recoveryUser({ commit }, data) {
    try {
      const url = '/usuarios/forgot_password';
      const response = await axiosInstance.post(url, data);

      if (response?.status === 200) {
        const status = response.data.status;
        const msg = response.data.msg;

        if (response?.data?.status) {
          if (!status) {
            Swal.fire({
              icon: 'error',
              title: msg,
              confirmButtonColor: '#268af7',
            });
            return false;
          } else {
            Swal.fire({
              icon: 'success',
              title: msg,
              confirmButtonColor: '#268af7',
            });
            commit('SET_DATA', { key: 'recovery', data: true });
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: msg,
            confirmButtonColor: '#268af7',
          });
          return false;
        }
      } else {
        throw new Error('No se pudo obtener la información del registro');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error de conexión',
        text: 'Hubo un problema al conectarse al servidor. Por favor, inténtalo de nuevo más tarde.',
        confirmButtonColor: '#268af7',
      });
      return false;
    }
  },
  async fetchUserData({ commit }, data) {
    try {
      const url = '/usuarios/get';
      const response = await axiosInstance.post(url, data);

      if (response?.status === 200) {
        const status = response.data.status;

        if (status) {
          const user = response.data.payload;
          commit('SET_DATA', { key: 'usuario', data: user || [] });
        }
      } else {
        throw new Error('No se pudo obtener la información del usuario');
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async edithUserData({ commit }, data) {
    try {
      const url = '/usuarios/edit';
      const response = await axiosInstance.post(url, data);

      if (response?.status === 200) {
        if (response.data.status) {
          const user = response.data.payload;
          commit('SET_DATA', { key: 'usuario', data: user || [] });
          return response.data;
        }
      } else {
        throw new Error('No se pudo obtener la información del usuario');
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async fetchTicketsList({ commit }, data) {
    try {
      const url = '/ordenes/get_by_user';
      const response = await axiosInstance.post(url, data);

      if (response?.status === 200) {
        const status = response.data.status;

        if (status) {
          const tickets = response.data.payload.tickets;
          commit('ADD_TO_LIST', { key: 'list', data: tickets.list || [] });
          commit('SET_DATA', { key: 'options', data: tickets.options || [] });
        }
      } else {
        throw new Error('No se pudo obtener la información del usuario');
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async sendContactData({ commit }, data) {
    try {
      const url = '/usuarios/contact';
      const response = await axiosInstance.post(url, data);

      if (response?.status === 200) {
        const status = response.data.status;
        commit('SET_DATA', { key: 'sendContact', data: status || false });
        return response.data;
      } else {
        throw new Error('No se pudo obtener la información del usuario');
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },
};

export default actions;