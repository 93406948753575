const state = {
  statesCatalog: [
    {
      "id": "14",
      "estado": "Jalisco",
      "siglas": "JAL",
      "paises_id": "0",
      "estatus": "1"
    },
  ],
  selectedState: null // Default not selected state
};

export default state;
