
const getters = {
  searchList: state => {
    return state.searchList.map(item => {
      return {
        ...item,
        title: item.titulo,
        description: item.descripcion_corta,
        location: item.descripcion_corta,
        image: item.portada,
      };
    });
  },
};

export default getters;
