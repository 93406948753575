const state = {
  event: [],
  cupon: false,
  order: [],
  statistics: [],
  qrTickets: [],
  reservePlaces: [],
};

export default state;
