// lang.js
const LANG_KEY = 'lang';

export function getLanguage() {
  return localStorage.getItem(LANG_KEY) || 'es'; // Default to SPANISH if no language is set
}

export function setLanguage(lang) {
  localStorage.setItem(LANG_KEY, lang);
}
