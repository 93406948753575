import Layout from '@/layouts/Root/index.vue';
import Home from '@/modules/Root/index.vue';
import Blog from '@/modules/Root/Blog/blog.vue';  
import BlogCategories from '@/modules/Root/Blog/index.vue';
import BlogList from '@/modules/Root/Blog/list.vue';
import Billboard from '@/modules/Root/Billboard/index.vue';
import EventDetails from '@/modules/Root/Billboard/event-details.vue';
import Loyalty from '@/modules/Root/Loyalty/index.vue';
import LoyaltyProcess from '@/modules/Root/Loyalty/process.vue';
import Theater from '@/modules/Root/Theater/index.vue';
import TheaterDetail from '@/modules/Root/Theater/theater.vue';
import EventStatistics from '@/modules/Root/Billboard/statistics.vue';
import CheckInTickets from '@/modules/Root/Billboard/check-in-tickets.vue';
import SelectedState from '@/modules/Root/selectedState.vue';
import Profile from '@/modules/Root/Auth/profile.vue';
import PagesContents from '@/modules/Root/pagesContents.vue';
import Contact from '@/modules/Root/contact.vue';
import NotFound from '@/modules/Root/404.vue';
import Statesroutes from './States';

import navigationRules from './navigationRules';

export default [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '',
        component: Home,
        name: 'home',
        beforeEnter: navigationRules.requireSelectedState
      },
      {
        path: 'blog',
        component: BlogCategories,
        name: 'blog',
        beforeEnter: navigationRules.requireSelectedState
      },
      {
        path: 'blog/categorias/:id',
        component: BlogList,
        name: 'blog-categories',
        beforeEnter: navigationRules.requireSelectedState
      },
      {
        path: 'blog/:id',
        component: Blog,
        name: 'blog-detail',
        beforeEnter: navigationRules.requireSelectedState
      },
      {
        path: 'cartelera',
        component: Billboard,
        name: 'cartelera',
        beforeEnter: navigationRules.requireSelectedState
      },
      {
        path: 'cartelera/:id',
        component: EventDetails,
        name: 'evento-detail',
        beforeEnter: navigationRules.requireSelectedState
      },
      {
        path: 'cartelera/evento/:id',
        component: EventDetails,
        name: 'evento-detail-evento',
        beforeEnter: navigationRules.requireSelectedState
      },
      {
        path: 'estadisticas/:code',
        component: EventStatistics,
        name: 'evento-estadisticas',
        beforeEnter: navigationRules.requireSelectedState
      },
      {
        path: 'check-in-tickets/:code/:funcion',
        component: CheckInTickets,
        name: 'check-in-tickets',
        beforeEnter: navigationRules.requireSelectedState
      },
      {
        path: 'espectador-frecuente',
        component: Loyalty,
        name: 'espectador-frecuente',
        beforeEnter: navigationRules.requireSelectedState
      },
      {
        path: 'espectador-frecuente/tramite',
        component: LoyaltyProcess,
        name: 'espectador-frecuente-tramite',
        beforeEnter: navigationRules.requireSelectedState
      },
      {
        path: 'teatros',
        component: Theater,
        name: 'teatros',
        beforeEnter: navigationRules.requireSelectedState
      },
      {
        path: 'teatro/:id',
        component: TheaterDetail,
        name: 'teatro-detail',
        beforeEnter: navigationRules.requireSelectedState
      },
      {
        path: '/selected-state',
        component: SelectedState,
        name: 'selected-state'
      },
      {
        path: '/usuario/mis-datos',
        component: Profile,
        name: 'user-profile',
        beforeEnter: navigationRules.requireSelectedState
      },
      {
        path: '/sobrevoyalteatro',
        component: PagesContents,
        name: 'home-contents-sobrevoyalteatro',
        beforeEnter: navigationRules.requireSelectedState
      },
      {
        path: '/aviso-de-privacidad',
        component: PagesContents,
        name: 'home-contents-aviso-de-privacidad',
        beforeEnter: navigationRules.requireSelectedState
      },
      {
        path: '/terminos-y-condiciones',
        component: PagesContents,
        name: 'home-contents-terminos-y-condiciones',
        beforeEnter: navigationRules.requireSelectedState
      },
      {
        path: '/terminos-y-condiciones-espectadorfrecuente',
        component: PagesContents,
        name: 'home-contents-terminos-y-condiciones-espectadorfrecuente',
        beforeEnter: navigationRules.requireSelectedState
      },
      {
        path: '/contacto',
        component: Contact,
        name: 'contact',
        beforeEnter: navigationRules.requireSelectedState
      },
      ...Statesroutes,
    ]
  },
  {
    path: '*',
    component: NotFound,
    name: 'NotFound'
  }
];
