<template>
  <div ref="imageContainer">
    <img :src="imageUrl" alt="Imagen" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ImageZoom from 'js-image-zoom'; // Asegúrate de importar ImageZoom de forma adecuada

export default {
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.ui.isMobile,
      isTablet: (state) => state.ui.isTablet,
    }),
  },
  mounted() {
    if (!this.isMobile && !this.isTablet) {
      var options = {
        fillContainer: true,
        zoomPosition: 'original',
      };

      new ImageZoom(this.$refs.imageContainer, options);
    }
  },
};
</script>
