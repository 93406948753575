<template>
  <section>
    <b-field>
      <b-datepicker
        ref="datepicker"
        expanded
        placeholder="Select a date"
        v-model="selectedDateComputed"
        :events="events.length ? generateEventDates() : transformedDates"
        :min-date="minDate"
        :max-date="maxDate"
        :inline="inline"
        :indicators="indicators"
        :position="position"
        icon="calendar"
        :icon-right="selectedDateComputed ? 'close-circle' : ''"
        icon-right-clickable
        @icon-right-click="clearDate"
        @input="handleDateChange"
      >
      </b-datepicker>
      <b-button @click="$refs.datepicker.toggle()" icon-left="calendar-today" type="is-info" />
    </b-field>
  </section>
</template>

<script>
const today = new Date();

export default {
  props: {
    events: {
      type: Array,
    },
    date: {
      type: Date,
    },
  },
  computed: {
    indicators() {
      return this.bars ? 'bars' : 'dots';
    },
    selectedDateComputed: {
      get() {
        return this.date ? new Date(this.date) : null;
      },
      set(value) {
        this.$emit('update:date', value); // Emite un evento para actualizar la propiedad `date` con el nuevo valor
      },
    },
  },
  data() {
    return {
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      maxDate: new Date(today.getFullYear() + 1, today.getMonth(), today.getDate()),
      bars: true,
      inline: false,
      position: 'is-bottom-left',
      transformedDates: [],
    };
  },
  methods: {
    generateEventDates() {
      return this.events.map((dateItem) => {
        const fecha = new Date(dateItem.fecha);
        fecha.setDate(fecha.getDate() + 1);

        return {
          date: fecha,
          type: 'is-info',
        };
      });
    },
    handleDateChange(selectedDate) {
      this.$emit('date-selected', selectedDate);
    },
    clearDate() {
      this.$emit('date-selected', null); // Emitir el evento de actualización con valor nulo para borrar la fecha
    },
  },
};
</script>