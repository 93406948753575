import Vue from 'vue';
import Vuex from 'vuex';
import statesCatalog from './statesCatalog';
import dashboard from './dashboard';
import billboard from './billboard';
import event from './event';
import theaters from './theaters';
import ui from './ui';
import blogs from './blogs';
import payment from './payment';
import auth from './auth';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    statesCatalog,
    dashboard,
    billboard,
    event,
    theaters,
    ui,
    blogs,
    payment,
    auth,
  },
});
