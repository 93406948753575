<template>
  <div>
    <router-link v-if="event.id != 'last'" :to="{ name: 'evento-detail', params: { id: event.id } }">
      <!--         <pre>
      {{ event }}
    </pre> -->
      <div class="card event-card" :style="cardStyle">
        <figure class="image is-square">
          <div v-if="event.estatus == 0" class="logo-local">
            <img src="@/assets/images/v-icon-full.png" alt="Local Event Logo" />
          </div>
          <div v-if="event.filtro_espectador == 1" class="logo-warranty">
            <img src="@/assets/images/star.png" alt="Warranty Logo" />
          </div>
          <div class="event-status">
            <p
              v-if="event.disponibilidad != 'disponible'"
              :class="['status-text', { 'last-tickets': event.disponibilidad === 'ultimos', 'sold-out': event.disponibilidad === 'agotado' }]"
            >
              {{ event.disponibilidad === 'ultimos' ? 'Últimos boletos' : 'Agotado' }}
            </p>
            <p v-if="event.funcion_agotada && event.disponibilidad != 'agotado'" class="deprecated-functions">Algunas funciones agotadas</p>
          </div>
          <div v-if="showDetails" class="event-details">
            <p class="event-name">{{ event.nombre }}</p>
            <p v-if="event.teatro_temporal == 1" class="event-theater">{{ event.teatro_nombre }}</p>
            <p v-else class="event-theater">{{ event.teatro }}</p>
          </div>
        </figure>
      </div>
    </router-link>
    <router-link v-else :to="{ name: 'cartelera', query: { page: 1, size: 20 } }">
      <div class="card event-card">
        <figure class="more-events image is-square">
          <div class="event-status">
            <p>Ver cartelera completa</p>
          </div>
        </figure>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'EventCard',
  props: {
    event: {
      type: Object,
      required: true,
    },
    showDetails: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    cardStyle() {
      return {
        backgroundImage: this.event ? `url(${this.event.portada}/500/500)` : 'none',
      };
    },
  },
  mounted() {
    // Cargar imágenes para precargarlas
    if (this.event.portada) {
      // Crea una nueva instancia de la clase Image
      const img = new Image();

      // Define una función que se ejecutará cuando la imagen se cargue correctamente
      img.onload = () => {
        // Aquí puedes realizar acciones adicionales después de que la imagen se haya cargado
        //console.log('La imagen se ha cargado correctamente');
      };

      // Define una función que se ejecutará si la carga de la imagen falla
      img.onerror = () => {
        // Aquí puedes manejar errores relacionados con la carga de la imagen
        //console.error('Error al cargar la imagen');
      };

      // Establece la URL de la imagen, redimensionada a 500x500 píxeles
      img.src = `${this.event.portada}/500/500`;
    }
  },
};
</script>

<style scoped>
/* Estilos generales */

.event-card {
  width: 100%;
  border-radius: 35px;
  background-size: cover;
  color: white;
  overflow: hidden;
  position: relative;
  background-attachment: local;
  box-shadow: none;
}

.event-card .image {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 150%);
}

.logo-local,
.logo-warranty,
.event-status,
.event-details {
  position: absolute;
  z-index: 1; /* Ajustamos el z-index según sea necesario */
}

.logo-local {
  top: 0;
  left: 0;
  width: 100%; /* Ancho relativo al contenedor */
  height: 100%; /* Altura ajustada automáticamente para mantener la proporción */
}

.logo-warranty {
  top: 20px;
  right: 20px;
  width: 8%; /* Ancho relativo al contenedor */
  height: 8%; /* Altura ajustada automáticamente para mantener la proporción */
}

.event-status {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.event-details {
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 20px 10px 20px;
}

.event-name {
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 1em;
}

.event-theater {
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5em;
  color: #aaaaaa;
}

.status-text {
  font-weight: bold;
  font-size: 2rem;
}

.last-tickets {
  color: #ffc107;
}

.sold-out {
  color: red;
}

.deprecated-functions {
  font-weight: bold;
  font-size: 1rem;
  color: #ffc107;
}

.event-card .more-events {
  background: #268af7;
}

.event-card .more-events p {
  font-size: 1.6rem;
  font-weight: 700;
  line-height: normal;
}
</style>