<template>
  <form @submit.prevent="register" class="register-form">
    <h3 class="is-size-5 has-text-weight-bold has-text-centered mb-2">{{ translatedText('register') }}</h3>
    <b-field :label="translatedText('name')" :message="nameError">
      <b-input v-model="name" type="text" :placeholder="translatedText('name')"></b-input>
    </b-field>

    <b-field :label="translatedText('last_name')" :message="lastNameError">
      <b-input v-model="lastName" type="text" :placeholder="translatedText('last_name')"></b-input>
    </b-field>

    <b-field :label="translatedText('email')" :message="emailError">
      <b-input v-model="email" type="email" :placeholder="translatedText('email')"></b-input>
    </b-field>

    <b-field :label="translatedText('state')" :message="selectedStateError">
      <b-select v-model="selectedState" :placeholder="translatedText('state')" expanded>
        <template v-for="state in statesCatalog">
          <option v-if="state.estado && state.siglas !== '' && state.siglas != 'ALL'" :key="state.id" :value="state.id">
            {{ state.estado.toUpperCase() }}
          </option>
        </template>
      </b-select>
    </b-field>

    <b-field :label="translatedText('password')" :message="passwordError">
      <b-input v-model="password" type="password" password-reveal :placeholder="translatedText('password')"></b-input>
    </b-field>
    <b-button @click="register" type="is-info" expanded>{{ translatedText('register_button') }}</b-button>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      name: '',
      lastName: '',
      email: '',
      password: '',
      emailError: null,
      passwordError: null,
      nameError: null,
      lastNameError: null,
      selectedState: null,
      selectedStateError: null,
    };
  },
  computed: {
    ...mapState({
      statesCatalog: (state) => state.statesCatalog.statesCatalog,
    }),
  },
  methods: {
    ...mapActions('auth', ['registerUser']),
    async register() {
      // Validar campos antes de enviar
      if (!this.name) {
        this.nameError = this.translatedText('please_enter_name');
        return;
      }

      if (!this.lastName) {
        this.lastNameError = this.translatedText('please_enter_last_name');
        return;
      }

      if (!this.email) {
        this.emailError = this.translatedText('please_enter_email');
        return;
      }

      if (!this.password) {
        this.passwordError = this.translatedText('please_enter_password');
        return;
      }

      if (!this.selectedState) {
        this.selectedStateError = this.translatedText('please_select_state');
        return;
      }

      if (this.password.length < 5) {
        this.passwordError = this.translatedText('password_length_error');
        return;
      }

      const authData = await this.registerUser({
        nombre: this.name,
        apellido: this.lastName,
        email: this.email,
        tipo_sesion: 'web',
        password: this.password,
        estados_id: this.selectedState,
      });

      // Guardar los datos en el almacenamiento local
      localStorage.setItem('usuario', JSON.stringify(authData));
      localStorage.setItem('token', authData.token);
      localStorage.setItem('token_sesion', authData.token_sesion);
    },
  },
};
</script>
