import axiosInstance from '../../api/axiosInstance';

const actions = {
  async fetchTheaters({ commit }, queryParams) {
    try {
      const url = '/teatros';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);

      if (response?.status === 200 && response?.data?.payload) {
        const teatros = response.data.payload.teatros;
        const filters = response.data.payload.filtros;
        
        commit('SET_DATA', { key: 'list', data: teatros || [] });
        commit('SET_DATA', { key: 'filters', data: filters || []});
      } else {
        throw new Error('No se pudo obtener la información de los teatros');
      }
    } catch (error) {
      console.error('Error al obtener los teatros:', error);
    }
  },
  async fetchTheater({ commit }, queryParams) {
    try {
      const url = '/teatros/get_by_id';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);

      if (response?.status === 200 && response?.data?.payload) {
        const teatro = response.data.payload.teatros;
        commit('SET_DATA', { key: 'theater', data: teatro || [] });
      } else {
        throw new Error('No se pudo obtener la información de los teatros');
      }
    } catch (error) {
      console.error('Error al obtener los teatros:', error);
    }
  },
};

export default actions;