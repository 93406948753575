const getters = {
  fechaData: (state) => (funcionId) => {
    if (state && state.statistics && state.statistics.fechas && typeof state.statistics.fechas[Symbol.iterator] === 'function') {
      // Iterar sobre todas las fechas en las estadísticas
      for (const fecha of state.statistics.fechas) {
        // Verificar si hay funciones en la fecha actual
        if (fecha.funciones && fecha.funciones.length > 0) {
          // Verificar si la función con el ID proporcionado está presente en esta fecha
          if (fecha.funciones.some((funcion) => funcion.id === funcionId)) {
            // Si la función está presente, devolver los datos de la fecha
            return { fecha: fecha.fecha, funcion: fecha.funciones.find((funcion) => funcion.id === funcionId) };
          }
        }
      }
    }
    // Manejar el caso en que no se encuentre la función o los datos no sean válidos
    return null;
  },
};

export default getters;
