const state = {
  usuario: {},
  token: null,
  token_sesion: null, 
  accountModal: false,
  list: [],
  options: [],
  sendContact: false,
};

export default state;
