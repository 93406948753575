<template>
  <div>
    <b-carousel
      :indicator="indicator"
      :indicator-inside="indicatorInside"
      :indicator-style="indicatorStyle"
      :arrow-hover="arrowHover"
      :pause-info="pauseInfo"
      :icon-prev="iconPrev"
      :icon-next="iconNext"
      :icon-size="iconSize"
      :has-drag="true"
    >
      <b-carousel-item v-for="(item, i) in items" :key="i">
        <template>
          <b-image v-once class="image" :src="item.image + '/1400/382'" role="button" tabindex="0"></b-image>
        </template>
        <div class="overlay-text">
          <h3 v-if="item.contenido_titulo">{{ item.contenido_titulo }}</h3>
          <p v-if="item.contenido_texto_1" class="text_1">{{ item.contenido_texto_1 }}</p>
          <p v-if="item.contenido_texto_2" class="text_2">{{ item.contenido_texto_2 }}</p>
          <p v-if="item.contenido_texto_3" class="text_3">{{ item.contenido_texto_3 }}</p>
          <b-button v-if="item.url" tag="a" :href="item.url" :target="item.target == 'interno' ? '_self' : '_blank'" type="is-info" rounded
            >Más información</b-button
          >
        </div>
      </b-carousel-item>
      <template v-if="indicatorImages" #indicators="props">
        <b-image class="al image" :src="items[props.i].image" :title="props.i"></b-image>
      </template>
    </b-carousel>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    pauseInfo: {
      type: Boolean,
      default: false,
    },
    arrowHover: {
      type: Boolean,
      default: false,
    },
    iconPrev: {
      type: String,
      default: 'chevron-left-circle-outline',
    },
    iconNext: {
      type: String,
      default: 'chevron-right-circle-outline',
    },
    iconSize: {
      type: String,
      default: 'is-large',
    },
    indicatorStyle: {
      type: String,
      default: 'is-lines',
    },
    indicatorInside: {
      type: Boolean,
      default: false,
    },
    indicator: {
      type: Boolean,
      default: true,
    },
    indicatorImages: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items2: [this.items[0]],
    };
  },
  mounted() {
    this.loadImages();
  },
  methods: {
    loadImages() {
      // Precargar imágenes con un retraso
      this.items.forEach((item, index) => {
        setTimeout(() => {
          // Crea una nueva instancia de la clase Image
          const img = new Image();

          // Define una función que se ejecutará cuando la imagen se cargue correctamente
          img.onload = () => {
            // Aquí puedes realizar acciones adicionales después de que la imagen se haya cargado
            //console.log(`La imagen ${item.image} se ha cargado correctamente`);
          };

          // Define una función que se ejecutará si la carga de la imagen falla
          img.onerror = () => {
            // Aquí puedes manejar errores relacionados con la carga de la imagen
            console.error(`Error al cargar la imagen ${item.image}`);
          };

          // Establece la URL de la imagen, redimensionada a 1400x382 píxeles
          img.src = `${item.image}/1400/382`;
        }, index * 2000); // Cambia el valor de 1000 a la cantidad de milisegundos que deseas de retraso entre cada imagen
      });
    },
  },
};
</script>


<style scoped>
.carousel-item-content {
  position: relative;
}

.overlay-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centra verticalmente el contenido */
  align-items: center; /* Centra horizontalmente el contenido */
  text-align: center;
  color: white;
  width: 50%;
  height: 100%; /* Altura específica */
  word-break: break-word;
  overflow: hidden;
  background: linear-gradient(to right, #000000, rgba(0, 0, 0, 0) 85% 100%);
  overflow: hidden; /* Asegura que los elementos secundarios no se desborden */
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  padding: 10px 0 10px 75px;
}

.overlay-text h3 {
  font-weight: 800;
  font-size: 2.5rem;
  line-height: 1.2em;
  font-style: italic;
}

.overlay-text p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.2em;
}

.overlay-text .text_1 {
  color: #38b6ff;
}

.overlay-text .text_3 {
  font-size: 1.2rem;
}

.overlay-text .button {
  margin-top: 10px;
  font-weight: 600;
}

@media screen and (max-width: 1023px) {
  .overlay-text {
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, #000000 90%);
    border-radius: 25px;
    justify-content: flex-end;
    padding: 10px 10px 20px 10px;
  }

  .overlay-text h3 {
    font-size: 2.2rem;
  }

  .overlay-text p {
    font-size: 1.2rem;
  }

  .overlay-text .text_3 {
    font-size: 1rem;
  }
}
</style>