<template>
  <b-field :label="labelText">
    <b-autocomplete
      v-model="selectedOption"
      :data="data"
      :placeholder="labelText"
      field="title"
      icon-right="magnify"
      :loading="isFetching"
      @typing="onTyping"
      @select="onSelect"
    >
      <template slot-scope="props">
        <div class="media">
          <div class="media-left">
            <img width="32" :src="getImageUrl(props.option)" />
          </div>
          <div class="media-content">
            <b>
              {{ props.option.title }}
            </b>
            <br />
            <small>
              {{ locationTitle }} {{ props.option.location }}
            </small>
          </div>
        </div>
      </template>
    </b-autocomplete>
  </b-field>
</template>

<script>
export default {
  name: 'SearchAutocomplete',
  props: {
    labelText: {
      type: String,
      default: 'Buscar',
    },
    entityType: {
      type: String,
      default: 'elementos',
    },
    locationTitle: {
      type: String,
      default: '',
    },
    onTyping: {
      type: Function,
      default: () => {},
    },
    onSelect: {
      type: Function,
      default: () => {},
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedOption: null,
      isFetching: false,
    };
  },
  methods: {
    getImageUrl(option) {
      return option.image+'/30/30';
    },
  },
};
</script>

<style>
.media {
  display: flex;
  align-items: center;
}
.media-left {
  margin-right: 10px;
}
</style>
