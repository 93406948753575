<template>
  <footer v-if="selectedState" class="footer has-background-black">
    <section v-if="boletin">
      <div class="newsletter hero-body">
        <p class="title has-text-white">{{ translatedText('subscribe_newsletter') }}</p>
        <b-button
          class="vat-btn subtitle has-text-white"
          tag="a"
          target="_blank"
          :href="boletin.contenido"
          :label="translatedText('subscribe')"
          type="is-info"
          expanded
        />
      </div>
    </section>
    <section class="sitemap">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column">
            <ul>
              <li>
                <p class="title">{{ translatedText('menu') }}</p>
              </li>
              <li>
                <a href="/" class="router-link-exact-active router-link-active">{{ translatedText('home') }}</a>
              </li>
              <li>
                <a href="/cartelera" class="">{{ translatedText('showtimes') }}</a>
              </li>
              <li>
                <a href="/espectador-frecuente" class="">{{ translatedText('frequent_viewer') }}</a>
              </li>
              <li>
                <a href="/blog" class="">{{ translatedText('blog') }}</a>
              </li>
              <li>
                <a href="/teatros" class="">{{ translatedText('theaters') }}</a>
              </li>
              <li>
                <a href="/contacto" class="">{{ translatedText('contact') }}</a>
              </li>
            </ul>
          </div>
          <div class="column">
            <ul>
              <li>
                <p class="title">{{ translatedText('voy_al_teatro') }}</p>
              </li>
              <li>
                <router-link to="/sobrevoyalteatro" class="router-link-exact-active router-link-active">{{
                  translatedText('about_voy_al_teatro')
                }}</router-link>
              </li>
              <li>
                <router-link to="/aviso-de-privacidad">{{ translatedText('privacy_policy') }}</router-link>
              </li>
              <li>
                <router-link to="/terminos-y-condiciones">{{ translatedText('terms_and_conditions') }}</router-link>
              </li>
              <li>
                <router-link to="/terminos-y-condiciones-espectadorfrecuente">{{
                  translatedText('terms_and_conditions_frequent_viewer')
                }}</router-link>
              </li>
            </ul>
          </div>
          <div class="column">
            <ul>
              <li>
                <p class="title">{{ translatedText('related_links') }}</p>
              </li>
              <li v-for="enlace in enlaces" :key="enlace.id">
                <a :href="enlace.url" target="_blank">{{ enlace.nombre }}</a>
              </li>
            </ul>
          </div>
          <div class="column">
            <ul class="social-networks">
              <li>
                <a target="_blank" href="https://twitter.com/voyalteatrocom"><i class="fab fa-x-twitter"></i></a>
              </li>
              <li>
                <a target="_blank" href="https://www.youtube.com/user/voyalteatrocom"><i class="fab fa-youtube"></i></a>
              </li>
              <li>
                <a target="_blank" href="https://www.facebook.com/VoyalTeatrocom/"><i class="fab fa-facebook-f"></i></a>
              </li>
              <li>
                <a target="_blank" href="https://www.instagram.com/voyalteatrocom/"><i class="fab fa-instagram"></i></a>
              </li>
              <li>
                <a target="_blank" href="https://www.tiktok.com/@voyalteatrocom"><i class="fab fa-tiktok"></i></a>
              </li>
            </ul>
            <div class="buttons change-language is-centered mt-5">
              <b-button @click="changeLanguage('es')" type="is-dark">{{ translatedText('spanish') }}</b-button>
              <b-button @click="changeLanguage('en')" type="is-dark">{{ translatedText('english') }}</b-button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { setLanguage } from '@/locales/lang';

export default {
  data() {
    return {};
  },
  mounted() {
    this.loadDashboard();
  },
  computed: {
    ...mapState({
      selectedState: (state) => state.statesCatalog.selectedState,
      enlaces: (state) => state.dashboard.enlaces,
    }),
    ...mapGetters('dashboard', ['boletin']),
  },
  methods: {
    ...mapActions('dashboard', ['fetchDashboard']),
    loadDashboard() {
      this.fetchDashboard();
    },
    changeLanguage(lang) {
      setLanguage(lang);
      window.location.reload();
    },
  },
};
</script>
