var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"theater-page"},[_c('div',{staticClass:"theater-header"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.translatedText('theaters_page')))])]),_c('div',{staticClass:"filters"},[(_vm.filters.length > 0)?_c('div',{staticClass:"columns is-multiline is-mobile"},[_c('div',{staticClass:"column is-12"},[_c('div',{staticClass:"buttons is-justify-content-center"},[_c('b-button',{staticClass:"vat-btn",class:{ 'is-active': 'all' === _vm.activeFilter },attrs:{"type":"is-info"},on:{"click":function($event){return _vm.handleFilterClick('all')}}},[_vm._v(" "+_vm._s(_vm.translatedText('all_theaters'))+" ")]),_vm._l((_vm.filters),function(filter){return _c('b-button',{key:filter.id,staticClass:"vat-btn",class:{ 'is-active': filter.url === _vm.activeFilter },attrs:{"type":"is-info"},on:{"click":function($event){return _vm.handleFilterClick(filter)}}},[_vm._v(" "+_vm._s(filter.nombre)+" ")])})],2)])]):_vm._e()]),(_vm.list.length)?_c('div',{staticClass:"theater-list"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-multiline"},_vm._l((_vm.limitedList),function(theater){return _c('div',{key:theater.id,staticClass:"column is-4"},[_c('router-link',{attrs:{"to":{ name: 'teatro-detail', params: { id: theater.id } }}},[_c('div',{staticClass:"card theater-card",style:({
                backgroundImage: `url(${
                  theater.portada ? theater.portada + '/600/300' : 'https://voyalteatro.com/backend/api/img/render/9339/600/300'
                })`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }),on:{"error":function($event){theater.error = true}}},[_c('p',{staticClass:"theater-status"},[_vm._v(_vm._s(theater.nombre))])])])],1)}),0),(_vm.visibleTheaters < _vm.list.length)?_c('div',{staticClass:"columns is-mobile is-centered load-more"},[_c('div',{staticClass:"column is-half-desktop is-full-mobile is-full-tablet has-text-centered"},[_c('b-button',{staticClass:"is-medium vat-btn",attrs:{"type":"is-info","icon-right":"menu-down"},on:{"click":_vm.loadMoreTheaters}},[_vm._v(" "+_vm._s(_vm.translatedText('load_more_theaters'))+" ")])],1)]):_vm._e()])]):_vm._e(),_c('section',{staticClass:"google-adds"},[_c('GoogleAdds')],1),_c('section',{staticClass:"section caption-text"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.translatedText('contact_us_teather_message')))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }