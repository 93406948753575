import axiosInstance from '../../api/axiosInstance';

const actions = {
  async fetchDashboard({ commit }) {
    try {
      const response = await axiosInstance.get('/dashboard');

      if (response?.status === 200 && response?.data?.payload) {
        const dashboard = response.data.payload;

        commit('SET_DATA', { key: 'banners', data: dashboard.banners });
        commit('SET_DATA', { key: 'blog', data: dashboard.blog });
        commit('SET_DATA', { key: 'destacados', data: dashboard.destacados });
        commit('SET_DATA', { key: 'contenidos', data: dashboard.contenidos });
        commit('SET_DATA', { key: 'espectador', data: dashboard.espectador });
        commit('SET_DATA', { key: 'enlaces', data: dashboard.enlaces });
        commit('SET_DATA', { key: 'publicidad', data: dashboard.publicidad });
        commit('SET_DATA', { key: 'avisos', data: dashboard.avisos });
      } else {
        throw new Error('No se pudo obtener la información del dashboard');
      }
    } catch (error) {
      console.error('Error al obtener el dashboard:', error);
    }
  },
};

export default actions;
