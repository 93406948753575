import axiosInstance from '../../api/axiosInstance';

const actions = {
  async fetchCategories({ commit }, queryParams) {
    try {
      const url = '/blogs/categorias';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);

      if (response?.status === 200 && response?.data?.payload) {
        const categories = response.data.payload.categorias;

        commit('SET_DATA', { key: 'categories', data: categories || [] });
      } else {
        throw new Error('No se pudo obtener la información de la cartelera');
      }
    } catch (error) {
      console.error('Error al obtener la cartelera:', error);
    }
  },
  async fetchBlogsByCategories({ commit }, queryParams) {
    try {
      const url = '/blogs/list';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);

      if (response?.status === 200 && response?.data?.payload) {
        const blogs = response.data.payload.blogs;

        commit('ADD_TO_LIST', { key: 'list', data: blogs.list || [] });
        commit('SET_DATA', { key: 'options', data: blogs.options  || []});
      } else {
        throw new Error('No se pudo obtener la información de la cartelera');
      }
    } catch (error) {
      console.error('Error al obtener la cartelera:', error);
    }
  },
  async fetchSearchList({ commit }, queryParams) {
    try {
      const url = '/blogs/search';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);

      if (response?.status === 200 && response?.data?.payload) {
        const blogs = response.data.payload.blogs;

        commit('SET_DATA', { key: 'searchList', data: blogs || [] });
      } else {
        throw new Error('No se pudo obtener la información de la cartelera');
      }
    } catch (error) {
      console.error('Error al obtener la cartelera:', error);
    }
  },
  async fetchBlog({ commit }, queryParams) {
    try {
      const url = '/blogs/get_by_id';
      const params = new URLSearchParams(queryParams);
      const response = await axiosInstance.get(`${url}?${params.toString()}`);

      if (response?.status === 200 && response?.data?.payload) {
        const blog = response.data.payload.blog;
        commit('SET_DATA', { key: 'blog', data: blog || [] });
      } else {
        throw new Error('No se pudo obtener la información de los blog');
      }
    } catch (error) {
      console.error('Error al obtener los blog:', error);
    }
  },
};

export default actions;