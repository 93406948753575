<template>
  <div class="card theater-card" :style="cardStyle">
    <figure class="image is-5by3">
      <div v-if="showDetails" class="theater-details">
        <p class="theater-name">{{ theater.nombre }}</p>
        <p class="theater-estado">{{ theater.estado }}</p>
      </div>
    </figure>
  </div>
</template>

<script>
export default {
  name: 'theaterCard',
  props: {
    theater: {
      type: Object,
      required: true,
    },
    showDetails: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    cardStyle() {
      return {
        backgroundImage: this.theater ? `url(${this.theater.portada}/600/300)` : 'none',
      };
    },
  },
};
</script>

<style scoped>
/* Estilos generales */

.theater-card {
  width: 100%;
  border-radius: 35px;
  background-size: cover;
  color: white;
  overflow: hidden;
  position: relative;
}

.theater-card .image {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 110%);
}

.logo-local,
.logo-warranty,
.theater-status,
.theater-details {
  position: absolute;
  z-index: 1; /* Ajustamos el z-index según sea necesario */
}

.logo-local {
  top: 0;
  left: 0;
  width: 100%; /* Ancho relativo al contenedor */
  height: 100%; /* Altura ajustada automáticamente para mantener la proporción */
}

.logo-warranty {
  top: 20px;
  right: 20px;
  width: 8%; /* Ancho relativo al contenedor */
  height: 8%; /* Altura ajustada automáticamente para mantener la proporción */
}

.theater-status {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.theater-details {
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 20px 5px 20px;
}

.theater-name {
  font-weight: 800;
  font-size: 1.4rem;
  line-height: 1em;
}

.theater-estado {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.5em;
  color: #aaaaaa;
}

.status-text {
  font-weight: bold;
  font-size: 2rem;
}

.last-tickets {
  color: #ffc107;
}

.sold-out {
  color: red;
}
</style>