<template>
  <section class="home-pages-contents">
    <div class="container">
      <div class="columns is-centered">
        <div v-if="contenido" class="column is-12">
          <div class="html-pages-content" v-html="contenido.contenido"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      ruta: this.$route.path || '',
      parametro: '',
      params: [
        { key: '/sobrevoyalteatro', value: 6 },
        { key: '/aviso-de-privacidad', value: 2 },
        { key: '/terminos-y-condiciones', value: 3 },
        { key: '/terminos-y-condiciones-espectadorfrecuente', value: 1 },
        // Agrega más rutas y valores según sea necesario
      ],
    };
  },
  computed: {
    ...mapState({
      contenidos: (state) => state.dashboard.contenidos,
    }),
    contenido() {
      window.scrollTo(0, 0);

      const ruta = this.$route.path; // Obtener la ruta actual
      const parametro = this.params.find((item) => item.key == ruta); // Buscar el parámetro correspondiente

      if (parametro && this.contenidos && this.contenidos.length > 0) {
        // Verificar que haya un parámetro y contenidos disponibles
        const contenido = this.contenidos.find((item) => item.id == parametro.value); // Buscar el contenido correspondiente al parámetro
        return contenido || null; // Devolver el contenido si se encuentra, o null si no se encuentra
      }
      return null; // Devolver null si no se encuentra ningún contenido o no hay parámetro definido
    },
  },
};
</script>
