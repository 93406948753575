<template>
  <div></div>
</template>

<script>
import { mapState } from 'vuex';
import Swal from 'sweetalert2';

export default {
  computed: {
    ...mapState({
      notificationsSeen: (state) => state.ui.notificationsSeen,
      avisos: (state) => state.dashboard.avisos,
    }),
    notificationsModal() {
      return this.avisos.id && !this.notificationsSeen;
    },
  },
  watch: {
    notificationsModal: function (newValue) {
      const imagePath = require('@/assets/images/marketing.gif');

      if (newValue) {
        Swal.fire({
          iconHtml: `<img src="${imagePath}">`,
          title: this.avisos.titulo,
          text: this.avisos.mensaje,
          confirmButtonText: this.translatedText('confirm_button_text'),
          confirmButtonColor: '#268af7',
          allowOutsideClick: false, // Evitar que se cierre al hacer clic fuera
          allowEscapeKey: false, // Evitar que se cierre al presionar la tecla Escape
          customClass: {
            icon: 'swal-icon-custom',
            container: 'swal-container-custom',
          },
        }).then(() => {
          this.$store.commit('ui/SET_DATA', { key: 'notificationsSeen', data: true });
        });
      }
    },
  },
};
</script>

<style>
.swal-icon-custom {
  border: 0 !important;
  width: 9em;
  height: 9em;
}
.swal-container-custom .swal2-popup {
  width: 40em;
}
</style>