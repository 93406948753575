<template>
  <b-carousel-list
    :data="items"
    :items-to-show="computedItemsToShow"
    :has-drag="true"
    :arrow="true"
    :arrow-hover="arrowHover"
    :icon-prev="iconPrev"
    :icon-next="iconNext"
    :icon-size="iconSize"
  >
    <template #item="list">
      <EventCard :event="list" :show-details="true"/>
    </template>
  </b-carousel-list>
</template>

<script>
import { mapState } from 'vuex';
import EventCard from '../../modules/Root/Billboard/event-card';

export default {
  components: {
    EventCard,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    pauseInfo: {
      type: Boolean,
      default: false,
    },
    arrowHover: {
      type: Boolean,
      default: false,
    },
    iconPrev: {
      type: String,
      default: 'menu-left',
    },
    iconNext: {
      type: String,
      default: 'menu-right',
    },
    iconSize: {
      type: String,
      default: 'is-large',
    },
    indicatorInside: {
      type: Boolean,
      default: false,
    },
    indicator: {
      type: Boolean,
      default: false,
    },
    showContent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.ui.isMobile,
      isTablet: (state) => state.ui.isTablet,
    }),
    computedItemsToShow() {
      if (this.isMobile) {
        return 1;
      } else if (this.isTablet) {
        return 3;
      } else {
        return 5;
      }
    },
  },
};
</script>
