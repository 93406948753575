<script>
import { mapState } from 'vuex'; // Importa mapState desde Vuex

import headerRoot from '@/components/Root/header';
import footerRoot from '@/components/Root/footer';
import loadingRoot from '@/components/Common/loading';
import notificationsRoot from '@/components/Common/notifications';

export default {
  name: 'Root',
  components: {
    headerRoot,
    footerRoot,
    loadingRoot,
    notificationsRoot,
  },
  data() {
    return {
      // Variable para controlar la visibilidad del componente header
      showHeader: true,
    };
  },
  computed: {
    // Mapea la propiedad isMobile del estado de Vuex
    ...mapState({
      isMobile: (state) => state.ui.isMobile,
    }),
    $t() {
      return (key) => this.messages[this.lang][key];
    },
  },
  mounted() {
    // Verificar el ancho de la pantalla al montar el componente
    this.checkMobile();
    // Agregar un event listener para cambiar isMobile si la ventana se redimensiona
    window.addEventListener('resize', this.checkMobile);
    // Verificar la ruta actual al montar el componente
    this.checkRoute();

    // Verificar el dominio al montar el componente
    this.checkDomain();
    // Agregar un event listener para verificar el dominio si la URL cambia
    window.addEventListener('popstate', this.checkDomain);
  },
  beforeDestroy() {
    // Asegurarse de quitar el event listener al destruir el componente
    window.removeEventListener('resize', this.checkMobile);
    window.removeEventListener('popstate', this.checkDomain);
  },
  watch: {
    // Observa cambios en la ruta actual y ejecuta la función checkRoute
    $route() {
      this.checkRoute();
    },
  },
  methods: {
    checkMobile() {
      // Obtener el ancho de la ventana del navegador
      const screenWidth = window.innerWidth;
      // Definir los límites para móvil y tablet
      const mobileMaxWidth = 767; // Por ejemplo, 767px o cualquier otro valor según el diseño responsivo
      const tabletMaxWidth = 1023;

      // Determinar si es móvil (menor o igual al ancho máximo de móvil)
      const isMobile = screenWidth <= mobileMaxWidth;
      // Determinar si es tablet (mayor que móvil pero menor o igual al ancho máximo de tablet)
      const isTablet = screenWidth > mobileMaxWidth && screenWidth <= tabletMaxWidth;

      // Actualizar el estado en Vuex
      this.$store.commit('ui/SET_DATA', { key: 'isMobile', data: isMobile });
      this.$store.commit('ui/SET_DATA', { key: 'isTablet', data: isTablet });
    },
    checkRoute() {
      // Obtener la ruta actual
      const currentRoute = this.$route.name;
      // Verificar si la ruta actual es 'check-in-tickets'
      if (currentRoute === 'check-in-tickets' && this.isMobile) {
        // Ocultar el componente header si la ruta es 'check-in-tickets'
        this.showHeader = false;
      } else {
        // Mostrar el componente header para cualquier otra ruta
        this.showHeader = true;
      }
    },
    checkDomain() {
      // Obtener el host actual de la URL
      const currentHost = window.location.host;
      // Lista de dominios que deben redirigirse a 'voyalteatro.com'
      const redirectedDomains = ['voyalteatro.com.mx', 'voyalteatro.mx'];
      // Verificar si el host actual está en la lista de dominios redirigidos
      if (redirectedDomains.includes(currentHost)) {
        // Redirigir a 'voyalteatro.com'
        window.location.href = 'https://voyalteatro.com';
      }
    },
  },
};
</script>

<template>
  <div class="main-root">
    <!-- Utilizar v-if para mostrar u ocultar el componente header -->
    <header-root v-if="showHeader"></header-root>
    <main class="root-content">
      <router-view />
    </main>
    <notifications-root></notifications-root>
    <loading-root></loading-root>
    <footer-root></footer-root>
  </div>
</template>
